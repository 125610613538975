import { Component } from '@angular/core';

@Component({
  selector: 'lockbin-equipments',
  templateUrl: './equipments.component.html',
  styles: [
  ]
})
export class EquipmentsComponent {

}
