import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { EquipmentsComponent } from './equipments.component';

// Internal modules
import { EquipmentsRoutingModule } from './equipments-routing.module';
import { SharedModule } from '../shared/shared.module';
import { StaticModule } from '../static/static.module';


@NgModule({
  declarations: [
    EquipmentsComponent
  ],
  imports: [
    CommonModule,

    // Internal modules
    EquipmentsRoutingModule,
    SharedModule,
    StaticModule
  ]
})
export class EquipmentsModule { }
